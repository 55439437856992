import { ChevronHeader } from 'assets/svgIcons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Breadcrumbs = ({ name, category }) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  return (
    <div className="store__product--breadcrumbs">
      <span className="text-Grey500 cursor-pointer" onClick={goBack}>
        {t('Shop')}
      </span>
      <ChevronHeader width={8} height={8} />
      {!!category && (
        <>
          <span>{category}</span>
          <ChevronHeader width={8} height={8} />
        </>
      )}
      <span className="text-Green600">{name}</span>
    </div>
  );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
