const theme = {
  screens: {
    sm: "640px",
    md: "820px",
    lg: "1024px",
    "lg-xl": "1152px",
    xl: "1280px",
    "2xl": "1536px",
    xs: "400px",
  },
  lineClamp: { 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" },
  colors: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  spacing: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
  },
  animation: {
    none: "none",
    spin: "spin 1s linear infinite",
    ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
    bounce: "bounce 1s infinite",
    "spin-slow": "spin 2s linear infinite",
    fadein: "fadein 0.5s",
    fadeout: "fadeout 0.5s",
    "slide-top": "slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
    "slide-bottom":
      "slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
  },
  backdropBlur: {
    0: "0",
    none: "0",
    sm: "4px",
    DEFAULT: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
    "2xl": "40px",
    "3xl": "64px",
  },
  backdropBrightness: {
    0: "0",
    50: ".5",
    75: ".75",
    90: ".9",
    95: ".95",
    100: "1",
    105: "1.05",
    110: "1.1",
    125: "1.25",
    150: "1.5",
    200: "2",
  },
  backdropContrast: {
    0: "0",
    50: ".5",
    75: ".75",
    100: "1",
    125: "1.25",
    150: "1.5",
    200: "2",
  },
  backdropGrayscale: { 0: "0", DEFAULT: "100%" },
  backdropHueRotate: {
    0: "0deg",
    15: "15deg",
    30: "30deg",
    60: "60deg",
    90: "90deg",
    180: "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-60": "-60deg",
    "-30": "-30deg",
    "-15": "-15deg",
  },
  backdropInvert: { 0: "0", DEFAULT: "100%" },
  backdropOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  backdropSaturate: { 0: "0", 50: ".5", 100: "1", 150: "1.5", 200: "2" },
  backdropSepia: { 0: "0", DEFAULT: "100%" },
  backgroundColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  backgroundImage: {
    none: "none",
    "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
    "gradient-to-tr": "linear-gradient(to top right, var(--tw-gradient-stops))",
    "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
    "gradient-to-br":
      "linear-gradient(to bottom right, var(--tw-gradient-stops))",
    "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
    "gradient-to-bl":
      "linear-gradient(to bottom left, var(--tw-gradient-stops))",
    "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
    "gradient-to-tl": "linear-gradient(to top left, var(--tw-gradient-stops))",
  },
  backgroundOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  backgroundPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  backgroundSize: { auto: "auto", cover: "cover", contain: "contain" },
  blur: {
    0: "0",
    none: "0",
    sm: "4px",
    DEFAULT: "8px",
    md: "12px",
    lg: "16px",
    xl: "24px",
    "2xl": "40px",
    "3xl": "64px",
  },
  brightness: {
    0: "0",
    50: ".5",
    75: ".75",
    90: ".9",
    95: ".95",
    100: "1",
    105: "1.05",
    110: "1.1",
    125: "1.25",
    150: "1.5",
    200: "2",
  },
  borderColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
    DEFAULT: "#e5e7eb",
  },
  borderOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  borderRadius: {
    none: "0px",
    sm: "0.125rem",
    DEFAULT: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  borderWidth: { 0: "0px", 2: "2px", 4: "4px", 8: "8px", DEFAULT: "1px" },
  boxShadow: {
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    DEFAULT: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    none: "none",
    big: "0px 2px 32px rgba(0, 0, 0, 0.04)",
    tab: "inset 0 4px 0 0 rgb(var(--theme-color-2))",
  },
  caretColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  contrast: {
    0: "0",
    50: ".5",
    75: ".75",
    100: "1",
    125: "1.25",
    150: "1.5",
    200: "2",
  },
  container: {},
  content: { none: "none" },
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    help: "help",
    "not-allowed": "not-allowed",
  },
  divideColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
    DEFAULT: "#e5e7eb",
  },
  divideOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  divideWidth: { 0: "0px", 2: "2px", 4: "4px", 8: "8px", DEFAULT: "1px" },
  dropShadow: {
    sm: "0 1px 1px rgba(0,0,0,0.05)",
    DEFAULT: ["0 1px 2px rgba(0, 0, 0, 0.1)", "0 1px 1px rgba(0, 0, 0, 0.06)"],
    md: ["0 4px 3px rgba(0, 0, 0, 0.07)", "0 2px 2px rgba(0, 0, 0, 0.06)"],
    lg: ["0 10px 8px rgba(0, 0, 0, 0.04)", "0 4px 3px rgba(0, 0, 0, 0.1)"],
    xl: ["0 20px 13px rgba(0, 0, 0, 0.03)", "0 8px 5px rgba(0, 0, 0, 0.08)"],
    "2xl": "0 25px 25px rgba(0, 0, 0, 0.15)",
    none: "0 0 #0000",
  },
  fill: { current: "currentColor" },
  grayscale: { 0: "0", DEFAULT: "100%" },
  hueRotate: {
    0: "0deg",
    15: "15deg",
    30: "30deg",
    60: "60deg",
    90: "90deg",
    180: "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-60": "-60deg",
    "-30": "-30deg",
    "-15": "-15deg",
  },
  invert: { 0: "0", DEFAULT: "100%" },
  flex: {
    1: "1 1 0%",
    auto: "1 1 auto",
    initial: "0 1 auto",
    none: "none",
    "48%": "1 1 48%",
  },
  flexGrow: { 0: "0", DEFAULT: "1" },
  flexShrink: { 0: "0", DEFAULT: "1" },
  fontFamily: {
    sans: [
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Noto Sans"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: [
      "ui-serif",
      "Georgia",
      "Cambria",
      '"Times New Roman"',
      "Times",
      "serif",
    ],
    mono: [
      "ui-monospace",
      "SFMono-Regular",
      "Menlo",
      "Monaco",
      "Consolas",
      '"Liberation Mono"',
      '"Courier New"',
      "monospace",
    ],
    "lora-bold": ["LoraBold", "serif"],
    lora: ["LoraRegular", "serif"],
  },
  fontSize: {
    xs: ["0.75rem", { lineHeight: "1rem" }],
    sm: ["0.875rem", { lineHeight: "1.25rem" }],
    base: ["1rem", { lineHeight: "1.5rem" }],
    lg: ["1.125rem", { lineHeight: "1.75rem" }],
    xl: ["1.25rem", { lineHeight: "1.75rem" }],
    "2xl": ["1.5rem", { lineHeight: "2rem" }],
    "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
    "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
    "5xl": ["3rem", { lineHeight: "1" }],
    "6xl": ["3.75rem", { lineHeight: "1" }],
    "7xl": ["4.5rem", { lineHeight: "1" }],
    "8xl": ["6rem", { lineHeight: "1" }],
    "9xl": ["8rem", { lineHeight: "1" }],
    xxs: ".625rem",
    xxxs: ".5rem",
  },
  fontWeight: {
    thin: "100",
    extralight: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
    inherit: "inherit",
  },
  gap: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
  },
  gradientColorStops: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  gridAutoColumns: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridAutoRows: {
    auto: "auto",
    min: "min-content",
    max: "max-content",
    fr: "minmax(0, 1fr)",
  },
  gridColumn: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-7": "span 7 / span 7",
    "span-8": "span 8 / span 8",
    "span-9": "span 9 / span 9",
    "span-10": "span 10 / span 10",
    "span-11": "span 11 / span 11",
    "span-12": "span 12 / span 12",
    "span-full": "1 / -1",
  },
  gridColumnEnd: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    auto: "auto",
  },
  gridColumnStart: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    13: "13",
    auto: "auto",
  },
  gridRow: {
    auto: "auto",
    "span-1": "span 1 / span 1",
    "span-2": "span 2 / span 2",
    "span-3": "span 3 / span 3",
    "span-4": "span 4 / span 4",
    "span-5": "span 5 / span 5",
    "span-6": "span 6 / span 6",
    "span-full": "1 / -1",
  },
  gridRowStart: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    auto: "auto",
  },
  gridRowEnd: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    auto: "auto",
  },
  gridTemplateColumns: {
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
    7: "repeat(7, minmax(0, 1fr))",
    8: "repeat(8, minmax(0, 1fr))",
    9: "repeat(9, minmax(0, 1fr))",
    10: "repeat(10, minmax(0, 1fr))",
    11: "repeat(11, minmax(0, 1fr))",
    12: "repeat(12, minmax(0, 1fr))",
    none: "none",
  },
  gridTemplateRows: {
    1: "repeat(1, minmax(0, 1fr))",
    2: "repeat(2, minmax(0, 1fr))",
    3: "repeat(3, minmax(0, 1fr))",
    4: "repeat(4, minmax(0, 1fr))",
    5: "repeat(5, minmax(0, 1fr))",
    6: "repeat(6, minmax(0, 1fr))",
    none: "none",
  },
  height: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    full: "100%",
    screen: "100vh",
    7.5: "1.875rem",
    8.5: "2.125rem",
    "52px": "3.25rem",
    80.5: "322px",
    fit: "fit-content",
    map: "500px",
  },
  inset: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    200: "200px",
    233: "233px",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-xxs": "-0.125rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    full: "100%",
    "-1/2": "-50%",
    "-1/3": "-33.333333%",
    "-2/3": "-66.666667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-full": "-100%",
  },
  keyframes: {
    spin: { to: { transform: "rotate(360deg)" } },
    ping: { "75%, 100%": { transform: "scale(2)", opacity: "0" } },
    pulse: { "50%": { opacity: ".5" } },
    bounce: {
      "0%, 100%": {
        transform: "translateY(-25%)",
        animationTimingFunction: "cubic-bezier(0.8,0,1,1)",
      },
      "50%": {
        transform: "none",
        animationTimingFunction: "cubic-bezier(0,0,0.2,1)",
      },
    },
    fadein: {
      "0%": { opacity: 0, transform: "translateX(100%)" },
      "100%": { opacity: 1, transform: "translateX(0%)" },
    },
    fadeout: {
      "0%": { opacity: 1, transform: "translateX(0%)" },
      "100%": { opacity: 0, transform: "translateX(100%)" },
    },
    "slide-top": {
      "0%": { transform: "translateY(10px)" },
      to: { transform: "translateY(0)" },
    },
    "slide-bottom": {
      "0%": { transform: "translateY(0)" },
      to: { transform: "translateY(10px)", opacity: 0 },
    },
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0em",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    3: ".75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  listStyleType: { none: "none", disc: "disc", decimal: "decimal" },
  margin: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    59: "14.75rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-xxs": "-0.125rem",
    "0-auto": "0 auto",
    11.5: "45px",
    17.5: "4.375rem",
  },
  maxHeight: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    full: "100%",
    screen: "100vh",
    "80vh": "80vh",
  },
  maxWidth: {
    0: "0rem",
    22: "5.5rem",
    28: "7rem",
    450: "28.125rem",
    1128: "70.5rem",
    none: "none",
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    "7xl": "80rem",
    full: "100%",
    min: "min-content",
    max: "max-content",
    prose: "65ch",
    "screen-sm": "640px",
    "screen-md": "820px",
    "screen-lg": "1024px",
    "screen-lg-xl": "1152px",
    "screen-xl": "1280px",
    "screen-2xl": "1536px",
    "screen-xs": "400px",
    thumbnail: "2.5rem",
    screen: "100vw !important",
    readable: "10ch",
    fit: "fit-content",
    34.5: "34.5rem",
    "storefront--products-container": "58.5rem",
  },
  minHeight: {
    0: "0px",
    10: "2.5rem",
    15: "3.75rem",
    16: "4rem",
    25: "6.25rem",
    52: "13rem",
    full: "100%",
    screen: "100vh",
    15.125: "3.875rem",
    "1/3-vh": "33.33vh",
    hero: "27rem",
  },
  minWidth: {
    0: "0px",
    10: "2.5rem",
    20: "5rem",
    25: "6.25rem",
    30: "7.5rem",
    44: "11rem",
    48: "12rem",
    64: "16rem",
    90: "22.5rem",
    full: "100%",
    min: "min-content",
    max: "max-content",
    "1/5": "20%",
    "2/5": "40%",
    "264px": "16.5rem",
    "260px": "16.25rem",
    table: "70.625rem",
    screen: "100vw",
  },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  opacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  order: {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    first: "-9999",
    last: "9999",
    none: "0",
  },
  outline: {
    none: ["2px solid transparent", "2px"],
    white: ["2px dotted white", "2px"],
    black: ["2px dotted black", "2px"],
    green: ["2px solid #3F826E4D"],
  },
  padding: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "14px",
    xxs: "0.125rem",
    1.75: "0.438rem",
    4.5: "18px",
  },
  placeholderColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  placeholderOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  ringColor: {
    DEFAULT: "#3b82f6",
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  ringOffsetColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  ringOffsetWidth: { 0: "0px", 1: "1px", 2: "2px", 4: "4px", 8: "8px" },
  ringOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
    DEFAULT: "0.5",
  },
  ringWidth: {
    0: "0px",
    1: "1px",
    2: "2px",
    4: "4px",
    8: "8px",
    DEFAULT: "3px",
  },
  rotate: {
    0: "0deg",
    1: "1deg",
    2: "2deg",
    3: "3deg",
    6: "6deg",
    12: "12deg",
    45: "45deg",
    90: "90deg",
    180: "180deg",
    "-180": "-180deg",
    "-90": "-90deg",
    "-45": "-45deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  saturate: { 0: "0", 50: ".5", 100: "1", 150: "1.5", 200: "2" },
  scale: {
    0: "0",
    50: ".5",
    75: ".75",
    90: ".9",
    95: ".95",
    100: "1",
    105: "1.05",
    110: "1.1",
    125: "1.25",
    150: "1.5",
  },
  sepia: { 0: "0", DEFAULT: "100%" },
  skew: {
    0: "0deg",
    1: "1deg",
    2: "2deg",
    3: "3deg",
    6: "6deg",
    12: "12deg",
    "-12": "-12deg",
    "-6": "-6deg",
    "-3": "-3deg",
    "-2": "-2deg",
    "-1": "-1deg",
  },
  space: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-xxs": "-0.125rem",
  },
  stroke: { current: "currentColor" },
  strokeWidth: { 0: "0", 1: "1", 2: "2" },
  textColor: {
    transparent: "transparent",
    current: "currentColor",
    black: "#000",
    white: "#fff",
    gray: {
      50: "#f9fafb",
      100: "#f3f4f6",
      200: "#e5e7eb",
      300: "#d1d5db",
      400: "#9ca3af",
      500: "#6b7280",
      600: "#4b5563",
      700: "#374151",
      800: "#1f2937",
      900: "#111827",
    },
    red: {
      50: "#fef2f2",
      100: "#fee2e2",
      200: "#fecaca",
      300: "#fca5a5",
      400: "#f87171",
      500: "#ef4444",
      600: "#dc2626",
      700: "#b91c1c",
      800: "#991b1b",
      900: "#7f1d1d",
    },
    yellow: {
      50: "#fffbeb",
      100: "#fef3c7",
      200: "#fde68a",
      300: "#fcd34d",
      400: "#fbbf24",
      500: "#f59e0b",
      600: "#d97706",
      700: "#b45309",
      800: "#92400e",
      900: "#78350f",
    },
    green: {
      50: "#F3F8F1",
      100: "#e7ecdf",
      200: "#c8d9bf",
      300: "#9fbf97",
      400: "#6b9e6b",
      500: "#487f50",
      600: "#3B6C4A",
      700: "#2a5a41",
      800: "#1d4434",
      900: "#16322b",
      950: "#0e201e",
    },
    blue: {
      50: "#eff6ff",
      100: "#dbeafe",
      200: "#bfdbfe",
      300: "#93c5fd",
      400: "#60a5fa",
      500: "#3b82f6",
      600: "#2563eb",
      700: "#1d4ed8",
      800: "#1e40af",
      900: "#1e3a8a",
    },
    indigo: {
      50: "#eef2ff",
      100: "#e0e7ff",
      200: "#c7d2fe",
      300: "#a5b4fc",
      400: "#818cf8",
      500: "#6366f1",
      600: "#4f46e5",
      700: "#4338ca",
      800: "#3730a3",
      900: "#312e81",
    },
    purple: {
      50: "#f5f3ff",
      100: "#ede9fe",
      200: "#ddd6fe",
      300: "#c4b5fd",
      400: "#a78bfa",
      500: "#8b5cf6",
      600: "#7c3aed",
      700: "#6d28d9",
      800: "#5b21b6",
      900: "#4c1d95",
    },
    pink: {
      50: "#fdf2f8",
      100: "#fce7f3",
      200: "#fbcfe8",
      300: "#f9a8d4",
      400: "#f472b6",
      500: "#ec4899",
      600: "#db2777",
      700: "#be185d",
      800: "#9d174d",
      900: "#831843",
    },
    Green50: "rgb(var(--theme-color-1))",
    Green600: "rgb(var(--theme-color-2))",
    Green700: "rgb(var(--theme-color-3))",
    Green800: "rgb(var(--theme-color-4))",
    White: "#FFFFFF",
    Grey000: "#FFFFFF",
    Grey50: "#fafafa",
    Grey100: "#f5f5f5",
    Grey200: "#e5e5e5",
    Grey300: "#d4d4d4",
    Grey400: "#a3a3a3",
    Grey500: "#737373",
    Grey600: "#525252",
    Grey700: "#404040",
    Grey800: "#262626",
    Grey900: "#171717",
    Grey950: "#0a0a0a",
    Green100: "#e7ecdf",
    Green200: "#c8d9bf",
    Green300: "#9fbf97",
    Green400: "#6b9e6b",
    Green500: "#487f50",
    Green900: "#16322b",
    Green950: "#0e201e",
    Beige50: "#f6f3ef",
    Beige100: "#eeebe2",
    Beige200: "#ddd7c6",
    Beige300: "#c8bfa2",
    Beige400: "#b4a47e",
    Beige500: "#a49065",
    Beige600: "#957f5b",
    Beige700: "#7a674d",
    Beige800: "#655643",
    Beige900: "#493f32",
    Beige950: "#2b251d",
    Beige1000: "#6D5C3E",
    Red50: "#FEF2F2",
    Red100: "#FEE2E2",
    Red200: "#FECACA",
    Red300: "#FCA5A5",
    Red400: "#F87171",
    Red500: "#EF4444",
    Red600: "#DC2626",
    Red700: "#B91C1C",
    Red800: "#991B1B",
    Red900: "#450a0a",
    Yellow50: "#fffbeb",
    Yellow100: "#fef3c7",
    Yellow200: "#fde68a",
    Yellow300: "#fcd34d",
    Yellow400: "#fbbf24",
    Yellow500: "#f59e0b",
    Yellow600: "#d97706",
    Yellow700: "#b45309",
    Yellow800: "#92400e",
    Yellow900: "#78350F",
    Yellow950: "#451a03",
    Blue50: "#F0F9FF",
    Blue100: "#E0F2FE",
    Blue200: "#BAE6FD",
    Blue300: "#7DD3FC",
    Blue400: "#38BDF8",
    Blue500: "#0EA5E9",
    Blue600: "#0284C7",
    Blue700: "#0369A1",
    Blue800: "#075985",
    Blue900: "#0C4A6E",
    Blue950: "#082f49",
    Purple50: "#faf5ff",
    Purple100: "#f3e8ff",
    Purple200: "#E9D5FF",
    Purple300: "#d8b4fe",
    Purple400: "#c084fc",
    Purple500: "#a855f7",
    Purple600: "#9333ea",
    Purple700: "#7e22ce",
    Purple800: "#6b21a8",
    Purple900: "#581c87",
    Purple950: "#3b0764",
    BlackOpacity10: "rgba(0, 0, 0, 0.1)",
    inherit: "inherit",
  },
  textOpacity: {
    0: "0",
    5: "0.05",
    10: "0.1",
    20: "0.2",
    25: "0.25",
    30: "0.3",
    40: "0.4",
    50: "0.5",
    60: "0.6",
    70: "0.7",
    75: "0.75",
    80: "0.8",
    90: "0.9",
    95: "0.95",
    100: "1",
  },
  transformOrigin: {
    center: "center",
    top: "top",
    "top-right": "top right",
    right: "right",
    "bottom-right": "bottom right",
    bottom: "bottom",
    "bottom-left": "bottom left",
    left: "left",
    "top-left": "top left",
  },
  transitionDelay: {
    75: "75ms",
    100: "100ms",
    150: "150ms",
    200: "200ms",
    300: "300ms",
    500: "500ms",
    700: "700ms",
    1000: "1000ms",
  },
  transitionDuration: {
    75: "75ms",
    100: "100ms",
    150: "150ms",
    200: "200ms",
    300: "300ms",
    500: "500ms",
    700: "700ms",
    1000: "1000ms",
    DEFAULT: "150ms",
  },
  transitionProperty: {
    none: "none",
    all: "all",
    DEFAULT:
      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    colors: "background-color, border-color, color, fill, stroke",
    opacity: "opacity",
    shadow: "box-shadow",
    transform: "transform",
  },
  transitionTimingFunction: {
    DEFAULT: "cubic-bezier(0.4, 0, 0.2, 1)",
    linear: "linear",
    in: "cubic-bezier(0.4, 0, 1, 1)",
    out: "cubic-bezier(0, 0, 0.2, 1)",
    "in-out": "cubic-bezier(0.4, 0, 0.2, 1)",
  },
  translate: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "-0": "0px",
    "-1": "-0.25rem",
    "-2": "-0.5rem",
    "-3": "-0.75rem",
    "-4": "-1rem",
    "-5": "-1.25rem",
    "-6": "-1.5rem",
    "-7": "-1.75rem",
    "-8": "-2rem",
    "-9": "-2.25rem",
    "-10": "-2.5rem",
    "-11": "-2.75rem",
    "-12": "-3rem",
    "-14": "-3.5rem",
    "-16": "-4rem",
    "-20": "-5rem",
    "-24": "-6rem",
    "-28": "-7rem",
    "-32": "-8rem",
    "-36": "-9rem",
    "-40": "-10rem",
    "-44": "-11rem",
    "-48": "-12rem",
    "-52": "-13rem",
    "-56": "-14rem",
    "-60": "-15rem",
    "-64": "-16rem",
    "-72": "-18rem",
    "-80": "-20rem",
    "-96": "-24rem",
    "-px": "-1px",
    "-0.5": "-0.125rem",
    "-1.5": "-0.375rem",
    "-2.5": "-0.625rem",
    "-3.5": "-0.875rem",
    "-xxs": "-0.125rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    full: "100%",
    "-1/2": "-50%",
    "-1/3": "-33.333333%",
    "-2/3": "-66.666667%",
    "-1/4": "-25%",
    "-2/4": "-50%",
    "-3/4": "-75%",
    "-full": "-100%",
    5.5: "1.375rem",
  },
  width: {
    0: "0px",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    7: "1.75rem",
    8: "2rem",
    9: "2.25rem",
    10: "2.5rem",
    11: "2.75rem",
    12: "3rem",
    14: "3.5rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    28: "7rem",
    32: "8rem",
    36: "9rem",
    40: "10rem",
    44: "11rem",
    48: "12rem",
    52: "13rem",
    56: "14rem",
    60: "15rem",
    64: "16rem",
    68: "16.5rem",
    72: "18rem",
    80: "20rem",
    96: "24rem",
    138: "34.5rem",
    auto: "auto",
    px: "1px",
    0.5: "0.125rem",
    1.5: "0.375rem",
    2.5: "0.625rem",
    3.5: "0.875rem",
    xxs: "0.125rem",
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
    screen: "100vw",
    min: "min-content",
    max: "max-content",
    "13%": "13%",
    "34px": "2.125rem",
    8.5: "2.125rem",
    7.5: "1.875rem",
    "48%": "48%",
    "hero-msg": "58.5rem",
    checkout: "70.5rem",
    "125px": "7.813rem",
    inherit: "inherit",
    fit: "fit-content",
  },
  zIndex: {
    0: "0",
    10: "10",
    20: "20",
    25: 25,
    30: "30",
    40: "40",
    50: "50",
    55: 55,
    60: 60,
    65: 65,
    70: 70,
    75: 75,
    80: 80,
    auto: "auto",
    modal: 1000,
    loading: 1500,
  },
  gridRows: { "2-auto": "auto auto" },
};

export default theme;
