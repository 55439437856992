import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BLANK_TEXT_EDITOR } from 'helpers/constants';
import Tabs from 'components/styled/Tabs';
import VendorCard from 'components/styled/VendorCard/VendorCard';

const DETAILS = 'DETAILS';
const ABOUT = 'ABOUT';

const ProductTabs = ({ description, vendor, classes }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    ...(description && description !== BLANK_TEXT_EDITOR
      ? [
          {
            id: DETAILS,
            name: t('storefront/product-details/tabs/details'),
          },
        ]
      : []),
    ...(vendor
      ? [
          {
            id: ABOUT,
            name: t('storefront/product-details/tabs/vendor'),
          },
        ]
      : []),
  ];
  if (tabs?.length === 0) return null;
  return (
    <div className={`flex flex-col mt-6 ${classes}`}>
      <Tabs
        classes="simple mb-4"
        setSelectedIndex={(i) => setSelectedTab(i)}
        selectedIndex={selectedTab}
        tabs={tabs}
        tabClasses="bg-transparent"
      />
      {tabs[selectedTab]?.id === DETAILS && (
        <p
          className="rich-text-editor-render text-Grey700 text-base"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {tabs[selectedTab]?.id === ABOUT && <VendorCard vendorData={vendor} />}
    </div>
  );
};

export default ProductTabs;
