import { Chevron } from 'assets/svgIcons/index';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { joinStrings, scrollTo } from 'helpers/helpers';
import Button from 'components/styled/Button/Button';
import InfoListTitle from 'components/styled/InfoList/InfoListTitle';
import DetailsPackageCard from './DetailsPackageCard/DetailsPackageCard';
import ProductTabs from './ProductTabs';

const Details = ({
  name,
  tags,
  packages,
  inventory,
  track_inventory,
  displayDiscount,
  vendor,
  availableFulfillmentOptions,
  description,
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  return (
    <div className="store__product--details col-span-2 sm:col-span-1 flex flex-col w-full sm:w-1/2">
      {vendor?.name && <h2 className="text-sm text-Green600 mb-1">{vendor?.name}</h2>}
      <h1 className="text-[2rem] font-bold text-Grey800 mb-1 leading-10">{name}</h1>
      <span className="text-sm text-gray-500">{joinStrings([tags.join(', ')], ' · ')}</span>
      <div className="flex flex-col">
        <InfoListTitle
          title={t('storefront/store/shop/product--options-available', {
            count: packages.length,
          })}
          classes="mb-4 mt-6"
        />
        {packages.map((_package) => (
          <DetailsPackageCard
            key={_package.id}
            inventory={inventory}
            {..._package}
            track_inventory={track_inventory}
            displayDiscount={displayDiscount}
            availableFulfillmentOptions={availableFulfillmentOptions}
          />
        ))}
      </div>
      <ProductTabs description={description} vendor={vendor} classes="block sm:hidden mb-6" />
      <Button
        classes="button button--white w-full items-center justify-center flex"
        onClick={goBack}>
        <Chevron classes="transform rotate-90 mr-2" />
        {t('Back to shop')}
      </Button>
    </div>
  );
};

Details.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  inventory: PropTypes.number,
  packages: PropTypes.array,
  track_inventory: PropTypes.bool,
  displayDiscount: PropTypes.bool,
  vendor: PropTypes.object,
  availableFulfillmentOptions: PropTypes.array,
};

Details.defaultProps = {
  id: null,
  name: '',
  tags: [],
  inventory: 0,
  packages: [],
  track_inventory: false,
  displayDiscount: false,
  vendor: null,
  availableFulfillmentOptions: [],
};

export default Details;
