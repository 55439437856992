import { datadogRum } from '@datadog/browser-rum';
import { api } from 'api/backoffice';
import { readExportStatus } from 'api/backoffice/orders';
import { readVendors } from 'api/backoffice/vendors';
import { ADJUSTMENT_TYPES, EXPORT_FILE_TYPE, VALIDATIONS } from 'helpers/constants';
import { useLocation } from 'react-router-dom';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const roundDecimal = (number, decimal = 2) => {
  return parseFloat(number).toFixed(decimal);
};

// If editing double check Safari works as expected
export const roundDecimalNumber = (num, decimals = 2) => {
  if (typeof num !== 'number' && (Number(num) == 0 || num.slice(-1) == '.')) return num;
  return Math.round(Number(num) * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

// used for calculating price that needs to be displayed
// as the adjusted price
export const calculateNewPrice = (initialPrice, adjustmentValue, adjustmentType) => {
  if (adjustmentType === ADJUSTMENT_TYPES.SET) {
    return adjustmentValue;
  }
  if (adjustmentType === ADJUSTMENT_TYPES.PERCENT) {
    return initialPrice + (adjustmentValue / 100) * initialPrice;
  }
  if (adjustmentType === ADJUSTMENT_TYPES.VALUE) {
    return initialPrice + adjustmentValue;
  }
  return;
};

export const sortObject = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      const newResult = result;
      newResult[key] = obj[key];
      return newResult;
    }, {});
};

export const slugify = (str) =>
  str
    ? str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
    : undefined;

export const validateFormInput = (value, validations) => {
  const results = {};
  validations.forEach((validation) => {
    switch (validation) {
      case VALIDATIONS.REQUIRED: {
        if (!value || !String(value).length) {
          results[validation] = false;
        } else {
          results[validation] = true;
        }
        break;
      }
      case VALIDATIONS.NUMBER: {
        if (!value || Number.isNaN(value)) {
          results[validation] = false;
        } else {
          results[validation] = true;
        }
        break;
      }
      case VALIDATIONS.POSITIVE_NUMBER: {
        if (Number.isNaN(value) || value < 0) {
          results[validation] = false;
        } else {
          results[validation] = true;
        }
        break;
      }
      case VALIDATIONS.GREATER_THAN_ZERO: {
        if (Number.isNaN(value) || value <= 0) {
          results[validation] = false;
        } else {
          results[validation] = true;
        }
        break;
      }
      case VALIDATIONS.TWO_DECIMALS: {
        if (value) {
          results.valueChanged = roundDecimal(value);
        } else {
          results.valueChanged = value;
        }
        break;
      }
      case VALIDATIONS.ARRAY_LENGTH: {
        if (value.length <= 0) {
          results[validation] = false;
        } else {
          results[validation] = true;
        }
        break;
      }
      default:
        break;
    }
  });
  return results;
};

export const createInputSelectOption = (label, value) => ({
  label,
  value: value || label.toLowerCase().replace(/\W/g, ''),
});

export const iOS = () =>
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios#9039885
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    window.navigator.platform
  ) ||
  // iPad on iOS 13 detection
  (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document);

// return the difference between two shallow arrays
// put the object you want the updated value from second
export const findDifferenceBetweenTwoObjects = (o1, o2) =>
  Object.keys(o2).reduce((diff, key) => {
    if (o1[key] === o2[key]) return diff;
    return {
      ...diff,
      [key]: o2[key],
    };
  }, {});

export const findDifferenceBetweenTwoArraysOfObjects = (a1, a2, key) =>
  a1.filter(({ [key]: id1 }) => !a2.some(({ [key]: id2 }) => id2 === id1));

export const arrayIntersection = (a1, a2) => {
  return a1.filter((v) => a2.includes(v));
};

export const arrayDifference = (a1, a2) => {
  return a1.filter((v) => !a2.includes(v));
};

// Note cannot contain objects
export const arraysEqual = (a1, a2) => JSON.stringify(a1) == JSON.stringify(a2);

export const collapseObjectToValue = (input, collapseKey = 'id') => {
  if (!isObject(input)) return input;
  if (collapseKey in input) return input[collapseKey];
  return null;
};

export const exportData = async (url, params) => {
  // used for exports without a modal (e.g. Customers, Price Lists)
  if (typeof params.direct === 'undefined') params.direct = true;

  const response = await api.get(url, {
    method: 'GET',
    responseType: 'blob', // important
    params,
  });

  if (params.direct) {
    const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
    const filename = response?.headers['content-disposition'].split('filename=')[1];
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute(
      'download',
      `${filename}.${EXPORT_FILE_TYPE[response?.headers['content-type']]}`
    );
    document.body.appendChild(link);
    link.click();
  }
};

export const exportDataAsync = async (file_path, params) => {
  // used for exports without a modal (e.g. Customers, Price Lists)
  const direct = params.get('direct') === 'true';
  if (typeof direct === undefined) params.append('direct', true);
  const TIME_BETWEEN_TRIES_MS = 1000;
  const MAX_TRIES = 150;

  const {
    data: { id },
  } = await api.get(file_path, {
    method: 'GET',
    params,
  });

  if (id && direct) {
    for (let i = 0; i < MAX_TRIES; i++) {
      await new Promise((resolve) => setTimeout(resolve, TIME_BETWEEN_TRIES_MS));
      const statusResponse = await readExportStatus(id);
      if (statusResponse?.data?.file_path) {
        const link = document.createElement('a');
        link.href = statusResponse?.data?.file_path;
        link.download = link.href;
        link.click();
        return true;
      }
    }
    return false;
  } else if (!direct) return true;
};

// checks to see if a provided value is a valid number representation
export const isNumber = (value) => {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
};

// converts camel case (e.g exampleValueVariable) to underscore case (e.g. example_value_variable)
export const camelToUnderscore = (key) => {
  var result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
};

// stringifies an array into comma separated strings
export const stringifyArray = (array) => {
  return array.reduce((a, m, i) => `${a}${i > 0 ? ',' : ''} ${m}`, '');
};

// given an array of Django Rest Framework errors, parse into field specific error array
export const formatErrors = (errors = {}) => {
  if (errors)
    return Object.entries(errors)?.map(([name, message]) => {
      if (typeof message === 'object') {
        return stringifyArray(Object.values(message));
      } else if (Array.isArray(message)) {
        return stringifyArray(message);
      } else if (typeof message === 'string') {
        return message;
      }
    });
};

export const formatErrorsToObject = (errors = {}) => {
  const formattedErrors = {};

  Object.entries(errors).forEach(([name, message]) => {
    if (typeof message === 'object') {
      formattedErrors[name] = formatErrorsToObject(message);
    } else if (Array.isArray(message)) {
      formattedErrors[name] = message.map((msg) => formatErrorsToObject(msg));
    } else {
      formattedErrors[name] = message;
    }
  });

  return formattedErrors;
};

// TODO: this should exist within custom formState hook. It is a temporary
// helper to turn the array of `errors` into an object where the fields are the keys
// when given an object of the shape specified here https://www.rfc-editor.org/rfc/rfc7807#section-3.1
export const formatProblemJSONErrors = (problemJSON) => {
  if (!problemJSON) return {};
  if (typeof problemJSON === 'string') return problemJSON;
  if (!('errors' in problemJSON)) {
    if (problemJSON?.detail) return { detail: problemJSON.detail };
    return {};
  }
  return problemJSON?.errors.reduce((a, error) => ({ ...a, [error?.field]: error?.message }), {});
};

export const removeHtmlTags = (str) => {
  if (str === null || str === '') return '';
  else str = str.toString();
  return str.replace(/(<([^>]+)>|(&nbsp;))/gi, '');
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// https://stackoverflow.com/a/16608074
export const isObject = (val) => {
  return !!val && val.constructor === Object;
};

export const isArray = (val) => {
  return val?.constructor === Array;
};

export const omitEmptyValueFromObject = (key, value) => {
  if (value === null) return {};
  if (isObject(value) && isObjectEmpty(value)) return {};
  if (isArray(value) && !value.length) return {};
  return { [key]: value };
};

export const omitKeysFromObject = (keys, object) => {
  return keys.reduce(
    (result, key) => {
      const { [key]: _, ...remaining } = result;
      return remaining;
    },
    { ...object }
  );
};

export const removeEmptyValuesFromObject = (object) =>
  Object.fromEntries(
    Object.entries(object).filter(([_, v]) => {
      if (!!v && typeof v === 'object') {
        if (Array.isArray(v)) return v.length > 0;
        return !isObjectEmpty(v);
      }
      return v !== undefined && v !== null;
    })
  );

export const addKeysToObject = (keys, object) => {
  Object.entries(keys).map(([key, value]) => {
    object[key] = object[value];
  });
  return object;
};

export const getNDaysFromNow = (n) => {
  const now = new Date();
  let nDaysFromNow = new Date();
  nDaysFromNow.setDate(now.getDate() + n);
  return nDaysFromNow;
};

export const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getScreenSize = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/*
  obtains the subdomain, domain and tld for the current browser location.
  if many parts return only the last subdomain.
  e.g. `https://1234.my-farm.example.com:3000` ->
  {
   'subdomain': 'my-farm',
   'domain': 'example.com',
   'tld': 'com',
   'host': '1234.my-farm.example.com:3000',
   'protocol': 'https',
   'fqdn': 'https://1234.my-farm.example.com:3000'
  }

 */
export const getDomainParts = () => {
  const regex = /(?:(?<subdomain>[A-Za-z0-9\-\_]+)\.){0,3}(?<domain>(?:[\w\-]+)\.(?<tld>[A-Za]+))/i;
  const { location } = window;
  const { host, protocol } = location;
  const fqdn = `${protocol}//${host}`;

  // TODO: Instead mock this in the tests
  if (location.hostname === 'localhost') {
    return {
      subdomain: 'test',
      domain: 'example.com',
      tld: 'com',
      host: 'test.example.com',
      protocol: 'https:',
      fqdn: 'https://test.example.com:3000',
    };
  }

  const {
    groups: { subdomain, domain, tld },
  } = regex.exec(host);
  return { subdomain, domain, tld, host, protocol, fqdn };
};

export const hasTrackOrCharge = (inventoryType, productState) => {
  //Todo: Move to product form
  return (
    productState.track_type.value?.value === inventoryType.value ||
    productState.charge_type.value?.value === inventoryType.value
  );
};

export const debounce = function (func, wait) {
  let timeout;
  return (...args) => {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const identifyRUM = (user, role) => {
  if (user?.id) {
    datadogRum.setUser({
      id: user?.id,
      name: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
      role: role,
    });
  }
};

export const identifySourcingRUM = (organization_user) => {
  if (organization_user?.id) {
    datadogRum.setUser({
      organization_id: organization_user?.organization_id,
      organization_user_id: organization_user?.id,
      name: `${organization_user?.first_name} ${organization_user?.last_name}`,
      email: organization_user?.email,
      organization_role_name: organization_user?.organization_role_name,
    });
  }
};

export const chunkArray = (array, size) => {
  if (array.length <= size) {
    return [array];
  }
  return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
};

export function getEarliestAvailableDate(availableDates) {
  /* availableDates is an array of objects with the following properties:
    - available_date
    - cutoff_datetime
    remove all available dates that are set before cutoff_datetime comparing to user's datetime */

  const now = new Date();
  const availableDatesAfterCutoff = availableDates.filter(
    (availableDate) => new Date(availableDate.cutoff_datetime) > now
  );

  if (availableDatesAfterCutoff.length === 0) {
    return null;
  }

  // return the earliest availableDatesAfterCutoff
  const earliestDate = availableDatesAfterCutoff.reduce((earliestAvailableDate, availableDate) => {
    if (
      new Date(earliestAvailableDate.available_date) < new Date(availableDate.available_date) ||
      new Date(earliestAvailableDate.cutoff_datetime) < new Date(availableDate.cutoff_datetime)
    ) {
      return earliestAvailableDate;
    }
    return availableDate;
  });

  return earliestDate;
}

export const labelHasDiscount = (displayDiscount, package_) =>
  displayDiscount &&
  package_?.package_price < package_?.original_package_price &&
  package_?.is_by_weight;

export const formatPhotoError = (errorCode) => {
  switch (errorCode) {
    case 'file-too-large':
      return 'global/file-too-large';
    default:
      break;
  }
  return 'Invalid File';
};

export const scrollTo = (left, top) =>
  window.scrollTo({
    left,
    top,
    behavior: 'smooth',
  });

export const formatLink = (link) =>
  link.indexOf('://') === -1 && link !== '' ? `http://${link}` : link;

export const sendEmailToSelected = (selected) => {
  let newMailToLink = `mailto:?bcc=`;
  selected.forEach(({ email }) => {
    if (email) newMailToLink += `${email},`;
  });
  window.location = newMailToLink;
};

export const joinStrings = (strings, separator = ' ') => {
  return strings.filter((string) => string).join(separator);
};

export const removeTrailingSlash = (str) => str.replace(/\/+$/, '');

export const getVendorsCount = async () => {
  try {
    const { data } = await readVendors();
    const { count } = data;
    return count;
  } catch (e) {
    console.error(e);
  }
};

export const daysSince = (date) => {
  const now = new Date();
  const then = new Date(date);
  const diff = now.getTime() - then.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24));
};

// combine arrays whilst preserving order of arrays and making each object unique
// lodash uniqBy prioritizes old sever results over new results
export const replaceThenMergeArrays = (array1, array2, key = id) => {
  const array2Obj = array2.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

  const mergedArray = array1.map((item) => (array2Obj[item[key]] ? array2Obj[item[key]] : item));

  array2.forEach((item) => {
    if (!array1.some((array1Item) => array1Item[key] === item[key])) {
      mergedArray.push(item);
    }
  });

  return mergedArray;
};

export const scrollToRef = (ref) => {
  if (!ref?.current) return;

  ref.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
  });
};

export const getGatewayDetails = (
  gatewayShort,
  gatewayKey,
  payrixMerchantId,
  customerId,
  payrixCustomersApi,
  countryCode
) => ({
  gatewayShort,
  gatewayKey,
  payrixMerchantId,
  customerId,
  payrixCustomersApi,
  countryCode,
});

export const dateUnavailableModifier = (day, availableDates) =>
  !availableDates.some((a) => {
    const availableDay = new Date(a.available_date.replace(/-/g, '/'));
    return (
      day.getFullYear() === availableDay.getFullYear() &&
      day.getMonth() === availableDay.getMonth() &&
      day.getDate() === availableDay.getDate()
    );
  });

export const getAvailableInventoryCount = (inventory, inventoryPerUnit) => {
  return Math.floor(inventory / inventoryPerUnit) < 0
    ? 0
    : Math.floor(inventory / inventoryPerUnit);
};

export const isTruthyExceptZero = (value) => (value !== 0 && Boolean(value)) || value === 0;

export const formatPayload = (obj, isFormData = true) => {
  // use FormData to support images
  let payload = isFormData ? new FormData() : {};
  Object.entries(obj)
    .filter(([_, { dirty }]) => dirty)
    .forEach(([_, { payloadKey, value }]) => {
      if (isFormData) {
        payload.append(payloadKey, value);
      } else {
        payload[payloadKey] = value;
      }
    });
  return payload;
};
