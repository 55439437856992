import { SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import tailwindTheme from 'styles/theme';
import './InputSelect.css';

// For adding new functionality please see
// https://react-select.com/props

const {
  Beige100,
  Green600,
  Green400,
  Green200,
  Grey50,
  Grey200,
  Grey300,
  Grey400,
  Grey500,
  Grey600,
  Grey700,
  Grey800,
  Grey900,
} = tailwindTheme.colors;

const customThemeColors = {
  primary: Green600,
  primary75: Green400,
  primary50: Green200,
  primary25: Grey50,
  neutral20: Grey200,
  neutral30: Grey300,
  neutral40: Grey400,
  neutral50: Grey500,
  neutral60: Grey600,
  neutral70: Grey700,
  neutral80: Grey800,
  neutral90: Grey900,
};

const customStyles = {
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: Beige100,
    color: Grey700,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  container: (base, { isDisabled }) => ({
    ...base,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '1rem',
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    color: isSelected ? Grey700 : Grey500,
    backgroundColor: isFocused ? Grey50 : 'transparent',
    ':hover': {
      backgroundColor: Grey50,
      color: Grey700,
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: '1rem',
  }),
};

const InputSelect = (props) => {
  const {
    options,
    creatable,
    value,
    isAsync,
    classes,
    isMulti,
    handleCreate,
    handleChange,
    isLoading,
    label,
    hint,
    id,
    placeholder,
    error,
    defaultValue,
    isDisabled,
    isClearable,
    loadOptions,
    formatOptionLabel,
    getOptionValue,
    onMenuScrollToBottom,
    cacheOptions,
    components: componentProps,
    defaultOptions,
    handleInputChange,
    isOptionDisabled, // custom function to determine if option disabled
    menuPlacement,
    menuPosition,
  } = props;

  return (
    <div className={`input-select ${classes} ${isDisabled ? 'disabled' : ''}`} id={id}>
      {label && <h4 className="mb-1">{label}</h4>}
      {creatable && !isAsync ? (
        <CreatableSelect
          {...props}
          options={options}
          isMulti={isMulti}
          value={value}
          onCreateOption={(e) => handleCreate(e)}
          onInputChange={handleInputChange}
          onChange={(e) => handleChange(e)}
          isLoading={isLoading}
          styles={customStyles}
          className={`${hint ? 'mb-1' : ''} text-sm ${error ? 'input-select--input__error' : ''}`}
          placeholder={placeholder}
          classNamePrefix="react-select"
          defaultValue={Object.keys(defaultValue).length ? defaultValue : null}
          isDisabled={isDisabled}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          getOptionValue={getOptionValue}
          onMenuScrollToBottom={onMenuScrollToBottom}
          cacheOptions={cacheOptions}
          components={{
            LoadingIndicator: () => <SpinnerSmall classes="mr-3" />,
            ...componentProps,
          }}
          isOptionDisabled={isOptionDisabled}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              ...customThemeColors,
            },
          })}
        />
      ) : null}
      {!creatable && !isAsync ? (
        <Select
          {...props}
          options={options}
          value={value}
          onChange={(e) => handleChange(e)}
          onInputChange={handleInputChange}
          isLoading={isLoading}
          styles={customStyles}
          isMulti={isMulti}
          className={`${hint ? 'mb-1' : ''} text-sm ${error ? 'input-select--input__error' : ''}`}
          placeholder={placeholder}
          classNamePrefix="react-select"
          defaultValue={Object.keys(defaultValue).length ? defaultValue : null}
          components={{
            LoadingIndicator: () => <SpinnerSmall classes="mr-3" />,
            ...componentProps,
          }}
          isDisabled={isDisabled}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          getOptionValue={getOptionValue}
          isOptionDisabled={isOptionDisabled}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              ...customThemeColors,
            },
          })}
          menuPortalTarget={document.body}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
        />
      ) : null}
      {!creatable && isAsync ? (
        // AsyncSelect takes the loadOptions prop instead
        // of the options prop
        <AsyncSelect
          {...props}
          loadOptions={loadOptions}
          onChange={handleChange}
          onInputChange={handleInputChange}
          value={value}
          isLoading={isLoading}
          isMulti={isMulti}
          styles={customStyles}
          className={`${hint ? 'mb-1' : ''} text-sm ${error ? 'input-select--input__error' : ''}`}
          placeholder={placeholder}
          classNamePrefix="react-select"
          defaultValue={defaultValue}
          defaultOptions={defaultOptions} // if true, will immediately fire request
          components={{
            LoadingIndicator: () => <SpinnerSmall classes="mr-3" />,
            ...componentProps,
          }}
          isDisabled={isDisabled}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          getOptionValue={getOptionValue}
          isOptionDisabled={isOptionDisabled}
          cacheOptions={cacheOptions}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              ...customThemeColors,
            },
          })}
        />
      ) : null}
      {creatable && isAsync && (
        <AsyncCreatableSelect
          {...props}
          loadOptions={loadOptions}
          value={value}
          onCreateOption={(e) => handleCreate(e)}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isLoading={isLoading}
          isMulti={isMulti}
          styles={customStyles}
          className={`${hint ? 'mb-1' : ''} text-sm ${error ? 'input-select--input__error' : ''}`}
          placeholder={placeholder}
          classNamePrefix="react-select"
          defaultValue={defaultValue}
          defaultOptions={defaultOptions} // if true, will immediately fire request
          components={{
            LoadingIndicator: () => <SpinnerSmall classes="mr-3" />,
            ...componentProps,
          }}
          isDisabled={isDisabled}
          isClearable={isClearable}
          formatOptionLabel={formatOptionLabel}
          getOptionValue={getOptionValue}
          isOptionDisabled={isOptionDisabled}
          cacheOptions={cacheOptions}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              ...customThemeColors,
            },
          })}
        />
      )}
      {error ? <p className="input-select--error">{error}</p> : null}
      {hint && <p>{hint}</p>}
    </div>
  );
};

InputSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  creatable: PropTypes.bool,
  isAsync: PropTypes.bool,
  isMulti: PropTypes.bool,
  classes: PropTypes.string,
  handleCreate: PropTypes.func,
  handleChange: PropTypes.func,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hint: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  loadOptions: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  components: PropTypes.object, // allows us to overwrite components that render
  defaultOptions: PropTypes.bool,
  isOptionDisabled: PropTypes.func, // custom function to determine if option disabled
  isSearchable: PropTypes.bool,
  menuPlacement: PropTypes.string,
  onMenuScrollToBottom: PropTypes.func,
  cacheOptions: PropTypes.bool,
  menuPosition: PropTypes.string,
};

InputSelect.defaultProps = {
  options: [],
  value: undefined,
  creatable: false,
  isAsync: false,
  isMulti: false,
  classes: '',
  handleCreate: () => {},
  handleChange: () => {},
  onMenuScrollToBottom: () => {},
  isLoading: false,
  label: '',
  hint: '',
  id: '',
  placeholder: '',
  error: '',
  defaultValue: {},
  isDisabled: false,
  isClearable: false,
  loadOptions: () => [],
  formatOptionLabel: null,
  components: {},
  defaultOptions: false,
  isOptionDisabled: () => {},
  isSearchable: true,
  cacheOptions: true,
  menuPlacement: 'auto',
  menuPosition: 'fixed',
};

export default InputSelect;
